@import "vars";
@import "mixins";

html,body {
    margin: 0 auto;
    padding: 0;
    // -ms-overflow-x: hidden;
    //     overflow-x: hidden;
}

body {
    font-family: $sans;
    position: relative;
    -ms-overflow-x: hidden;
        overflow-x: hidden;
}

// Default
.list-unstyled {
    margin: 0;
    padding: 0;

    list-style: none;
}

.tac {
    text-align: center;
}

object {
    pointer-events: none;
}

.cf {
    @include clearfix;
}

// Buttons
input, button, textarea {
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    font-weight: normal;
}

ul {
    margin: 0;
    padding: 0;
    
    li {
        list-style-type: none;
    }
}

a {
    text-decoration: none;
    transition: all 0.4s;
    
    &:hover {
        opacity: 0.8;
    }
}

.inp-error {
    border-color: red!important;
    
    input {
        border-color: red!important;
    }
}

.contact__form {
    &-r {
        position: relative;
    }

    &-success {
        background: #FFF;
        border: 1px solid $black;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: 65px;
        text-align: center;
        display: none;
        
        span {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}

.video {
    &:hover {
        .btn-playvideo.is-hidden {
            opacity: 1;
            pointer-events: all;
            transform: translate3d(0, -50%, 0)!important;
        }
    }
}