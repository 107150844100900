.top {
	&__album {
		
		&.top {
			height: auto;
			margin: 0 0 230px 0;
		}

		&-in {
			position: relative;
			
			&.wrapper__in {
				padding-bottom: 100px;
			}
			
			&:before {
				position: absolute;
				content: ' ';
				top: 82px;
				right: 479px;
				bottom: 0;
				left: -3000px;
				background: $gray;
				z-index: -1;
			}

			.headline {
				&__big {
					span {
						display: flex;
						-webkit-justify-content: flex-end;
						        justify-content: flex-end;
						    
						i {
							display: inline-block;
							width: 350px;
							text-align: center;
							font-style: normal;
						}
					}
				}
			}
		}

		&-item {
			margin: 0 0 74px 0;
			
			&:last-child {
				margin: 0 0 0 0;
			}
			
			h5 {
				margin: 0 0 12px 0;
				
				@include h4;
				color: $black;
			}

			p {
				margin: 0 0 2px 0;
				
				@include h6;
				color: $black;
				
				&:last-child {
					margin: 0;
				}
			}
		}

		&-l {
			max-width: 400px;
			text-align: right;
		}

		&-r {
			width: calc(100% - 432px);
			padding: 0 0 0 32px;
			-webkit-box-sizing: border-box;
			   -moz-box-sizing: border-box;
			        box-sizing: border-box;
			
			.video {
				position: relative;
				
				.btn {
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					left: 0;
					right: 0;
					margin-left: auto;
					margin-right: auto;
					z-index: 100;
				}
			}
			
			video,
			iframe {
				display: block;
				width: 100%;
				height: 315px;
			}
		}

		&-wrapper {
			margin: 110px 0 0 0;
			display: flex;
			-webkit-justify-content: space-between;
			        justify-content: space-between;
		}

		&-logo {
			width: 350px;
			height: auto;
			position: absolute;
			bottom: 50px;
			right: 129px;
			display: flex;
			-webkit-justify-content: center;
			        justify-content: center;
			
			img {
				display: block;
				width: 112px;
				margin: 0 auto;
				height: auto;
			}
		}
	}
}