.gallery {
	margin: 310px 0 0 0;
	
	@media (max-width: 1024px) {
		.slick-dots {
			display: none!important;
		}

		.tab-pane {
			&.active {
				.gallery__items {
					& > .slick-dots {
						// display: block!important;
					}
				}
				
			}
		}
	}
	
	&__in {
		position: relative;
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		
		&:after {
			position: absolute;
			content: ' ';
			left: 0;
			right: 0;
			bottom: 0;
			height: 2px;
			background: #EFEFEF;
		}
	}

	&__l {

	}

	&__r {
		position: relative;
		
		&:before {
			position: absolute;
			content: ' ';
			height: 570px;
			top: -88px;
			left: 49px;
			right: -3000px;
			background: #EFEFEF;
			z-index: -1;
		}

		.tab {
			&-pane {
				@media (min-width: 1025px) {
					padding-right: 16px;
				}
			}
		}
	}

	&__txt {
		
		h5 {
			@include h3;
			
			margin: 0 0 35px 0;
			color: $black;
		}

		p {
			@include h6;
			
			margin: 0 0 10px 0;
			color: $black;
		}
	}

	&__items {
		max-width: 670px;
		padding: 40px 0 0 0;
		margin: 0 -11.5px;
		height: 760px;
		-ms-overflow-y: auto;
		    overflow-y: auto;
		position: relative;
		display: flex;
		-webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		        flex-wrap: wrap;
		
		@media (max-width: 1024px) {
			&.slick-dotted.slick-slider {
				margin-bottom: 0;
			}
		}
		
		&::-webkit-scrollbar {
		  width: 4px;
		}
		 
		&::-webkit-scrollbar-track {
		  background: transparent;
		}
		 
		&::-webkit-scrollbar-thumb {
		  background: #BABABA;
		}

		.slick-dots {
			margin-top: 10px;
			position: static;
			width: 100%!important;
		}
	}

	&__item {
		width: 33.33%;
		padding: 0 11.5px;
		height: 300px;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
		        box-sizing: border-box;
		margin-bottom: 30px;

		&:nth-child(1),
		&:nth-child(3),
		&:nth-child(4),
		&:nth-child(6),
		&:nth-child(7),
		&:nth-child(9),
		&:nth-child(10),
		&:nth-child(12),
		&:nth-child(13),
		&:nth-child(15),
		&:nth-child(16),
		&:nth-child(18),
		&:nth-child(19),
		&:nth-child(21) {
			margin-top: -40px;
		}

		// &:nth-child(3n) {
		// 	margin-top: -40px;
		// }

		&-in {
			width: 100%;
			height: 100%;
			
			img {
				display: block;
				width: 100%;
				height: 100%;
				
				object-fit: cover;
				object-position: top;
			}
		}
	}
}