.author {
	margin: 125px 0 155px 0;
	
	&__in {
		display: flex;
		-webkit-justify-content: space-between;
		        justify-content: space-between;
		-webkit-align-items: center;
		        align-items: center;
	}

	&__l {
		max-width: 320px;
	}

	&__r {

	}

	&__headline {
		margin: 0 0 35px 0;
		
		h5 {
			text-transform: uppercase;
			font-size: 48px;
			font-weight: $light;
			
			b {
				font-weight: $bold;
			}
		}
	}

	&__about {
		margin: 0 0 46px 0;
		
		p {
			@include h6;
			color: $black;
		}
	}

	&__btn {
		margin: 0 0 135px 0;
		
		.btn {
			text-transform: uppercase;
			letter-spacing: 3px;
		}
	}

	&__img {
		max-width: 425px;
		max-height: 820px;
		position: relative;
		
		&:before {
			position: absolute;
			content: ' ';
			top: 25px;
			left: -111px;
			right: -3000px;
			bottom: 304px;
			background: #EFEFEF;
			z-index: -1;
		}
		
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: relative;
		}
	}

	&__socials {
		display: flex;
		-webkit-justify-content: center;
		        justify-content: center;
		ul {
			font-size: 0;
			display: flex;
			-webkit-align-items: center;
			        align-items: center;
			
			li {
				display: inline-block;
				margin: 0 39px 0 0;
				
				&:last-child {
					margin-right: 0;
				}

				a {
					display: block;
					
					&:hover {
						cursor: pointer;
						opacity: 0.5;
					}
				}
			}
		}

		.instagram {
			a {
				width: 27px;
				height: 26px;
				background: url('../img/socials/instagram.png') center center no-repeat;
				-webkit-background-size: contain;
				     -o-background-size: contain;
				        background-size: contain;
			}
		}

		.facebook {
			a {
				width: 24px;
				height: 25px;
				background: url('../img/socials/facebook.png') center center no-repeat;
				-webkit-background-size: contain;
				     -o-background-size: contain;
				        background-size: contain;
			}
		}

		.music {
			a {
				width: 19.44px;
				height: 22.43px;
				background: url('../img/socials/music.png') center center no-repeat;
				-webkit-background-size: contain;
				     -o-background-size: contain;
				        background-size: contain;
			}
		}

		.spotify {
			a {
				width: 25.42px;
				height: 28.41px;
				background: url('../img/socials/spotify.png') center center no-repeat;
				-webkit-background-size: contain;
				     -o-background-size: contain;
				        background-size: contain;
			}
		}

		.youtube {
			a {
				width: 25.42px;
				height: 28.93px;
				background: url('../img/socials/youtube.png') center center no-repeat;
				-webkit-background-size: contain;
				     -o-background-size: contain;
				        background-size: contain;
			}
		}
	}
}