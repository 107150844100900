.album {
	&__about {
		margin: 0 0 160px 0;
		
		&-in {
			display: flex;
			-webkit-justify-content: space-between;
			        justify-content: space-between;
		}

		&-headline {
			margin: 0 0 38px 0;
			
			h5 {
				@include h3;
				text-transform: uppercase;
			}
		}

		&-desc {
			p {
				@include h6;
				color: $black;
			}
		}

		&-l {
			max-width: 320px;
		}

		&-r {
			width: calc(100% - 420px);
			max-width: 375px;
			position: relative;
			
			&:before {
				position: absolute;
				content: ' ';
				left: -104px;
				top: -90px;
				right: -3000px;
				bottom: -60px;
				background: $gray;
				z-index: -1;
			}
			
			.top {
				&__music {
					&-items {
						padding: 0;
						margin: 0;
						
						.btn {
							&-play {
								background: url(../img/icons/play-black.svg) left center no-repeat;
    							background-size: 16px 23px;
							}
						}

						.is-playing {
							.btn {
								&-play {
									background: url(../img/icons/stop-black.svg) left center no-repeat;
    								background-size: 16px 18px;
								}
							}
						}
					}
				}
			}

			.music {
				&__items {
					margin: 50px 0 70px 0;
					
					&-btns {
						display: flex;
						-webkit-justify-content: space-between;
						        justify-content: space-between;
						    
						.btn {
							height: 43px;
							margin: 0 3px 0 0;
							line-height: 43px;
							text-transform: uppercase;
							letter-spacing: 2px;
							
							&:first-child {
								min-width: 105px;
							}

							&:nth-child(2) {
								width: calc(100% - 105px);
								margin: 0;
							}
						}
					}
				}

				&__item {
					margin: 0 0 15px 0;
					
					&-name {
						span {
							font-weight: $medium;
							font-family: $sans;
							
							i {
								font-style: normal;
								font-size: 14px;
								font-weight: $light;
							}
						}
					}
					
					&:last-child {
						margin: 0;
					}
					
					p {
						font-size: 16px;
						font-weight: $medium;
						color: $black;
						font-family: $sans;
						
						span:not(.hidden-xs) {
							font-weight: $light;
							font-size: 14px;
						}
					}

					.btn-play {
						margin-top: -2px;
					}
				}
			}
		}
	}
}