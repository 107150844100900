.projects {
	&__ensemble {
		padding: 150px 0 140px 0;
		
		&-headline {
			h5 {
				@include h3;
			}
		}
		
		&-items {
			display: flex;
			max-width: 800px;
			margin: 0 auto;
			-webkit-flex-wrap: wrap;
			    -ms-flex-wrap: wrap;
			        flex-wrap: wrap;
		}

		&-item {
			width: 33.33%;
			padding: 0 22px;
			position: relative;
			-webkit-box-sizing: border-box;
			   -moz-box-sizing: border-box;
			        box-sizing: border-box;
			margin: 0 0 20px 0;
		}

		&-img {
			width: 100%;
			padding-bottom: 100%;
			position: relative;
			
			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-desc {
			p {
				margin: 5px 0 0 0;
				
				@include h4;
				
				color: $black;
			}
		}

		&-date {
			position: absolute;
			transform: rotate(90deg) translate(0%, 0);
			top: 0;
			left: 100%;
			transform-origin: top left;
			
			span {
				@include h6;
				color: #000;
			}
		}
	}
}