@import "vars";

@mixin br($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin clearfix {
	&:after {
		content: ' ';

		visibility: hidden;
		display: block;
		height: 0;

		clear: both;
	}
}

@mixin placeholder-all {
    &.placeholder { @content }
    &:-moz-placeholder { @content }
    &::-webkit-input-placeholder { @content }
}

@mixin h3 {
    font-size: 30px;
    font-weight: $light;
}

@mixin h4 {
	font-size: 18px;
	font-weight: $medium;
}

@mixin h5 {
	font-size: 14px;
	font-weight: $medium;
}

@mixin h6 {
	font-size: 14px;
	font-weight: $light;
}