.booking {
	&__partners {
		padding: 60px 0 0px 0;
		
		&-in {
			display: flex;
			-webkit-justify-content: space-between;
			        justify-content: space-between;
		}

		&-l {
			max-width: 245px;
			text-align: right;
			position: relative;
			
			&:before {
				position: absolute;
				content: ' ';
				top: -60px;
				right: -150px;
				bottom: 0;
				left: -3000px;
				background: $gray;
				z-index: -1;
			}
		}

		&-r {
			max-width: 385px;
		}

		&-headline {
			margin: 0 0 38px 0;
			
			h5 {
				@include h3;
				color: $black;
				text-transform: uppercase;
			}
		}

		&-desc {
			p {
				font-size: 14px;
				font-weight: $light;
				color: $black;
				margin: 0 0 15px 0;
			}
		}

		&-form {
			position: relative;
			margin-bottom: 220px;
			
			.booking {
				&__partners {
					&-headline {
						position: absolute;
						transform: rotate(270deg) translate(0, -125%);
						top: 0;
						right: 100%;
						transform-origin: right top;
					}
				}
			}
		}
	}
}