.top {
	&__booking {
		&.top {
			height: auto;
			padding-bottom: 60px;
		}

		&-wrapper {
			display: flex;
			-webkit-justify-content: space-between;
			        justify-content: space-between;
			max-width: 600px;
			margin: 71px auto 0 auto;
			position: relative;
			left: -65px;
			
			&:before {
				position: absolute;
				content: ' ';
				top: -167px;
				left: -3000px;
				bottom: -60px;
				right: 110px;
				background: $gray;
				z-index: -1;
			}
		}

		&-txt,
		&-headline {
			text-align: right;
		}

		&-headline {
			margin: 0 0 52px 0;
			
			h5 {
				font-size: 48px;
				font-weight: $light;
				text-transform: uppercase;
				margin: 0 0 10px 0;
				
				b {
					font-weight: $bold;
				}
			}

			p {
				@include h4;
			}
		}

		&-txt {
			margin: 0 0 25px 0;
			
			p {
				@include h6;
				margin: 0 0 15px 0;
				
				&:last-child {
					margin: 0 0 0 0;
				}
			}
		}

		&-btn {
			display: flex;
			-webkit-justify-content: flex-end;
			        justify-content: flex-end;
			    
			    .btn {
			    	text-transform: uppercase;
			    	letter-spacing: 2px;
			    }
		}

		&-l {
			width: calc(100% - 265px);
		}

		&-r {
			width: 220px;
			margin: -40px 0 -30px 0;
			
			img {
				display: block;
				width: 100%;
				height: 100%;
				
				object-fit: cover;
			}
		}
	}
}