.top {
	&__music {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		left: 0;
		right: 0;
		z-index: 11;
		
		
		&-headline {
			h5 {
				font-size: 48px;
				font-weight: $light;
				color: #fff;
				text-transform: uppercase;
				
				b {
					font-weight: $bold;
				}
			}
		}

		&-in {
			display: flex;
			padding-left: 70px;
			position: relative;
			
			.btn-video {
				position: absolute;
				right: 129px;
				bottom: 0;
				
				@media(min-width: 1025px) {
					display: inline-block;
				    min-width: initial;
				    padding-left: 42px;
				    padding-right: 42px;
				    height: 45px;
				    line-height: 41px;
				    text-align: center;
				    border: 2px solid #fff;
				    -webkit-box-sizing: border-box;
				       -moz-box-sizing: border-box;
				            box-sizing: border-box;
				}
			}
			
			& > .top {
				&__music {
					&-headline {
						padding: 37px 15px 0 0;
					}
				}
			}
		}

		&-wrapper {
			display: inline-block;
			border: 2px solid #fff;
			padding: 35px 10px;
			min-width: 347px;
			-webkit-box-sizing: border-box;
			   -moz-box-sizing: border-box;
			        box-sizing: border-box;
			position: relative;
		}

		&-items {
			margin: 35px 0 0 0;
			padding: 0 30px;
			
			.btn {
				&-play {
					min-width: 20px;
					width: 23px;
					height: 23px;
					background: url('../img/icons/play.svg') left center no-repeat;
					-webkit-background-size: 16px 23px;
					     -o-background-size: 16px 23px;
					        background-size: 16px 23px;
					
					&:hover {
						cursor: pointer;
					}
				}

				&-more {
					margin: 18px 0 0 0;
					color: #fff;
				}

				&-arrow {
					position: absolute;
					bottom: -2px;
					left: -78px;
				}
			}

			.music {
				&__item {
					display: flex;
					margin-bottom: 10px;
					
					&:last-child {
						margin-bottom: 0;
					}
					
					&-l {
						width: 35px;
					}
					
					&-r {
						width: calc(100% - 35px);
					}

					&.is-playing {
						.btn-play {
							background: url('../img/icons/stop.svg') left center no-repeat;
							-webkit-background-size: 16px 18px;
							     -o-background-size: 16px 18px;
							        background-size: 16px 18px;
						}
					}

					&-waves {
						height: 30px;
						overflow: hidden;
						
						& > wave {
							transform: translate(0, 14px);
						}
					}
				}
			}
		}

		.music {
			&__item {
				&-name {
					span {
						@include h6;
						
						display: block;
						margin: 5px 0 0 0;
						text-align: left;
						
						color: #fff;
					}
				}
			}
		}
	}

	&__index {
		position: relative;
		
		&:after {
			position: absolute;
			content: ' ';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(0,0,0,0.3);
		}

		video {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			object-fit: cover;
			display: block;
			width: 100%;
			height: 100%;
		}

		&-btn {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 100px;
			z-index: 10;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			-webkit-justify-content: center;
			        justify-content: center;
			
			.btn {
				letter-spacing: 2px;
				text-transform: uppercase;
				border: 1px solid #fff;
				transform: translate(0, 200px);
				transition: all 1s;
			}
		}

		&-sound {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			left: 0;
			right: 0;
			z-index: 100;
			text-align: center;
			
			.btn {
				display: inline-block;
			    min-width: 200px;
			    height: 65px;
			    line-height: 65px;
			    text-align: center;
			    border: 1px solid #fff;
			    -webkit-box-sizing: border-box;
			       -moz-box-sizing: border-box;
			            box-sizing: border-box;
			}
		}
	}
}

.header__logo {
	transition: all 1s;
	opacity: 1;
	pointer-events: all;
	transform: translate3d(0, 0%, 0);
}

.header__r {
	transition: all 1s;
	opacity: 1;
	pointer-events: all;
	transform: translate3d(0%, 0%, 0);
}

.top__music-headline {
	h5 {
		transition: all 1s;
		opacity: 1;
		pointer-events: none;
		transform: translate3d(0%, 0%, 0);
	}
}

.top__music-items {
	opacity: 1;
	pointer-events: all;
	transition: all 1s;
	
	.btn-arrow {
		transition: all 1s;
		opacity: 1;
		pointer-events: all;
	}
}

.top__music-wrapper {
	transition: all 1s;
	border-color: #fff;
	pointer-events: all;
}

.top__music {
	transition: all 1s;
	opacity: 1;
	pointer-events: all;
}

.top__index-sound {
	opacity: 0;
	pointer-events: none;
}

.top__index-sound {
	transition: all 1s;
}

.is-active--start {
	.top__index {
		&-btn {
			.btn {
				transform: translate(0, 0px);
			}
		}
	}
}

.is-active--sound {
	.top__index-sound {
		opacity: 1;
		pointer-events: all;
	}
}

.is-active--video {
	overflow: hidden;
	
	.header__logo,
	.custom-logo-link {
		opacity: 0;
		pointer-events: none;
		transform: translate3d(0, -50%, 0);
	}

	.header__r {
		opacity: 0;
		pointer-events: none;
		transform: translate3d(50%, 0%, 0);
	}

	.top__music-headline {
		h5 {
			opacity: 1;
			pointer-events: none;
			transform: translate3d(0%, -50%, 0);
		}
	}

	.top__music-items {
		opacity: 0;
		pointer-events: none;
		
		.btn-arrow {
			opacity: 0;
			pointer-events: none;
		}
	}

	.top__music-wrapper {
		border-color: transparent;
		pointer-events: none;
	}

	.top__music {
		opacity: 0;
		pointer-events: none;
	}
}

.btn-hidden {
	.top__index-btn {
		.btn {
			transform: translate3d(0, 150%, 0);
			opacity: 0;
			pointer-events: none;
		}
	}

	.top__index-sound {
		opacity: 0;
		pointer-events: none;
	}
}

.btn-none {
	.top__index-btn {
		.btn {
			display: none;
		}
	}
}

.preloader--active {
	.preloader {
		opacity: 1;
		pointer-events: all;
		
		img {
			transform: translate3d(0,0,0) scale(1);
		}
	}
}

.preloader {
	position: fixed;
	top: 0;
	right: 0px;
	bottom: 0;
	left: 0;
	background: #1d1d1d;
	z-index: 1000;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	pointer-events: none;
	opacity: 0;
	transition: all 0.5s;
	
	img {
		width: 120px;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		transition: all 0.5s;
		
		transform: translate3d(0,0,0) scale(2);
	}
}