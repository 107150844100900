.top {
	&__musicpage {
		&.top {
			height: auto;
			margin: 0 0 145px 0;
			
			.headline {
				&__big {
					margin-bottom: 145px;
				}
			}
		}

		.music__items {
			position: relative;
			padding-bottom: 70px;
			
			&:after {
				position: absolute;
				content: ' ';
				left: -3000px;
				background: $gray;
				top: -250px;
				bottom: 0;
				right: 395px;
				z-index: -1;
			}

			.music {
				&__item {
					margin: 85px 0 0 0;
					
					&:first-child {
						margin-top: 0;
					}

					.albums__item {
						width: 100%;
						height: 200px;
						
						&-in {
							padding: 0;
						}

						&-cover {
							height: 200px;
						}

						&-img {
							height: 100%;
							img {
								height: 100%;
							}
						}
					}

					&-l {
						max-width: 204px;
						height: 200px;
						display: flex;
						-webkit-flex-direction: column;
						    -ms-flex-direction: column;
						        flex-direction: column;
						-webkit-justify-content: space-between;
						        justify-content: space-between;
						margin: 0 10px 0 0;
					}

					&-r {
						width: 475px;
					}

					&-name {
						p {
							@include h3;
							text-align: right;
						}
					}

					&-btn {
						.btn {
							height: 43px;
							line-height: 43px;
							text-transform: uppercase;
							letter-spacing: 2px;
						}
					}
				}
			}

			& > .music__item {
				display: flex;
				
				.music {
					&__item {

						&-l {
							.music__item {
								&-name {
									position: relative;
									top: -6px;
								}
							}
						}
					}
				}
			}

			.top {
				&__music {
					&-items {
						padding: 0;
						margin: 0;
						display: flex;
						-webkit-justify-content: space-between;
						        justify-content: space-between;
						-webkit-align-items: flex-end;
						        align-items: flex-end;
						
						.btn {
							&-play {
								background: url(../img/icons/play-black.svg) left center no-repeat;
    							background-size: 16px 23px;
							}
						}

						.is-playing {
							.btn {
								&-play {
									background: url(../img/icons/stop-black.svg) left center no-repeat;
    								background-size: 16px 18px;
								}
							}
						}

						.music {
							&__item {    
								width: calc(100% - 55px);
								margin: 10px 0 0 0;
								
								&-l {
									height: auto;
								}
								
								&-date {
									width: 55px;
									text-align: right;
									padding-bottom: 15px;
									
									span {
										@include h4;
										
										color: $black;
									}
								}
							}
						}
					}
				}
			}

			&-headline {
				position: absolute;
				top: 0;
				right: 0;
				height: 200px;
				width: 270px;
				text-align: center;
				display: flex;
				-webkit-justify-content: center;
				        justify-content: center;
				-webkit-align-items: flex-end;
				        align-items: flex-end;
				padding-bottom: 58px;
				
				h5 {
					@include h3;
					text-transform: uppercase;
					line-height: 1;
					height: 27px;
				}
			}
		}
	}
}