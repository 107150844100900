.about {
	&__top {
		margin: 0 0 60px 0;
		
		&-in {
			display: flex;
			-webkit-justify-content: space-between;
			        justify-content: space-between;
			    -webkit-align-items: center;
			            align-items: center;
		}

		&-l,
		&-r {
			width: 400px;
		}

		&-l {
			p {
				@include h6;
				color: $black;
				text-align: right;
			}
		}
	}

	&__item {
		display: flex;
		-webkit-align-items: center;
		        align-items: center;
		    -webkit-justify-content: space-between;
		            justify-content: space-between;
		    
		&:nth-child(2) {
			position: relative;
			z-index: 2;
			
			.about {
				&__item {
					&-l {
						margin: -30px 0 -11px 82px;
					}
				}
			}
		}

		&:nth-child(3) {
			.about {
				&__item {
					&-l {
						margin: 0 0 0 32px;
					}
				}
			}
		}
		     
		&-l,
		&-r {
			width: 400px;
		}

		&-img {
			width: 100%;
			
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		&-txt {
			p {
				margin: 0 0 10px 0;
				@include h6;
				color: $black;
				
				&:last-child {
					margin: 0;
				}
			}
		}

		.awards {
			position: relative;
			
			&__headline {
				transform: rotate(270deg) translate(0, -280%);
				transform-origin: right top;
				position: absolute;
				top: 0;
				right: 100%;
				
				span {
					@include h3;
					text-transform: uppercase;
					letter-spacing: 2px;
					line-height: 1;
				}
			}

			&__item {
				margin: 0 0 20px 0;
				
				p {
					@include h4;
					color: $black;
				}

				span {
					@include h6;
					color: $black;
				}
			}
		}
	}

	&__items {
		margin: 0 0 100px 0;
	}

	&__item {
		&-img {
			@media (min-width: 1025px) {
				position: relative;
			}
		}
	}
}

.top {
	&.top__about {
		height: auto;
		position: static;

		.top {
			&__headline {
				position: static;
				padding-top: 250px;
				
				@media (max-width: 1024px) {
					padding-top: 150px;
				}
			}
		}
	}
}