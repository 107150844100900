.albums {
	margin: 0 0 170px 0;
	
	&__headline {
		text-align: right;
		
		h5 {
			@include h3;
			color: $black;
			text-transform: uppercase;
		}
	}

	&__items {
		display: flex;
		padding: 0 0 0 82px;
		margin: 12px -27px 0 -27px;
		position: relative;
		
		.albums {
			&__headline {
				position: absolute;
				top: 0;
				right: 100%;
				white-space: nowrap;
				transform: rotate(270deg); 
    			transform-origin: right top;
    			
    			h5 {
    				line-height: 1;
    			}
			}
		}

		&-btn {
			margin: 44px 0 0 0;
			text-align: center;
			padding: 0 0 0 82px;
			
			.btn {
				position: relative;
				z-index: 1;
				min-width: 165px;
				text-transform: uppercase;
				letter-spacing: 3px;
			}
		}
	}

	&__item {
		width: 33.33%;
		
		&-in {
			width: 100%;
			-webkit-box-sizing: border-box;
			   -moz-box-sizing: border-box;
			        box-sizing: border-box;
			padding: 0 68px 0 27px;
		}

		&-img {
			height: 238px;
			position: relative;
			
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-desc {
			position: relative;
			margin: 12px 0 16px 0;
			padding: 0 40px 0 0;
		}

		&-name {
			span {
				display: block;
				margin: 0 0 2px 0;
				
				@include h6;
				
				b {
					@include h5;
				}
			}
		}

		&-date {
			position: absolute;
			top: 0;
			right: 0;
			
			@include h6;
		}

		&-controls {
			position: absolute;
			top: 0;
			bottom: 0;
			right: -41px;
			width: 41px;
			display: flex;
			-webkit-flex-direction: column;
			    -ms-flex-direction: column;
			        flex-direction: column;
			
			.btn-download {
				position: absolute;
				bottom: 0;
				right: 0;
			}

			.btn-rotate {
				height: 41px;
				color: #fff;
				font-weight: $medium;
				font-size: 14px;
				text-transform: uppercase;
				line-height: 41px;
				padding: 0 19px;
			}

			.btn {
				opacity: 1;
			}
		}

		&-btn {
			text-transform: uppercase;
			letter-spacing: 3px;
		}
	}

	&__in {
		position: relative;
		
		&:before {
			position: absolute;
			content: ' ';
			top: 103px;
			left: -3000px;
			right: 426px;
			bottom: -71px;
			background: #EFEFEF;
		}
	}
}

p {
	line-height: 150%;
}